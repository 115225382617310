import $ from "jquery";
import 'bootstrap';
$(function(){

    var searchTerm, panelContainerId, accordionID;

    $.expr[':'].containsCaseInsensitive = function (n, i, m) {
        return $(n).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
    };

    $('.ct-search-accordion').on('change keyup paste click', function(){
        searchTerm = $(this).val();
        accordionID = $(this).data('id');
        if (searchTerm.length >= 3) {
            $('#ct-accordion-' + accordionID + ' .elementor-accordion-item').each(function () {
                panelContainerId = '#ct-accordion-' + $(this).data('id');
                $(panelContainerId + ' .elementor-accordion-item:not(:containsCaseInsensitive(' + searchTerm + '))').hide();
                $(panelContainerId + ' .elementor-accordion-item:containsCaseInsensitive(' + searchTerm + ')').show().find('.collapsed').trigger("click");
            });
        }
        else {
            let currentAccordion = $('#ct-accordion-' + accordionID );
            currentAccordion.find('.elementor-accordion-item').show();
            let collapsed = currentAccordion.find('.elementor-accordion-item');
            if(!collapsed.hasClass( 'collapsed' )) currentAccordion.find('.elementor-tab-title').trigger("click");
        }
    });
});