// bootstrap
import "../../node_modules/bootstrap/scss/bootstrap-reboot.scss";
import "../../node_modules/bootstrap/scss/bootstrap-grid.scss";
import "../../node_modules/bootstrap/scss/bootstrap-utilities.scss";

// slick slider
import "../../node_modules/slick-carousel-latest/slick/slick.scss";

// style
import "../scss/styles.scss";

import "./scripts/accordion/base.js";
import "./scripts/header.js";
import "./scripts/footer.js";
